import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-good-chart3',
  templateUrl: './good-chart3.component.html',
  styleUrls: ['./good-chart3.component.scss']
})
export class GoodChart3Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
