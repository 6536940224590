import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { BkLayout } from '@uibakery/kit';
import { NbSidebarService } from '@nebular/theme';
import { ApiCallsService } from './services/api-calls.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})


export class AppComponent {
  currentYear = new Date().getFullYear();
  defaultLayout: BkLayout = {
    paddings: {
      paddingTop: 0,
      paddingRight: 0, 
      paddingBottom: 0,
      paddingLeft: 0,
      paddingTopUnit: 'px',
      paddingRightUnit: 'px',
      paddingBottomUnit: 'px',
      paddingLeftUnit: 'px'
    },
    header: true,
    sidebar: true
  };

  sideBarState: any = "expanded";
  public isTokenAvailable = localStorage.getItem('userDetails') == undefined ? false : true;
  layout$: Observable<BkLayout> = this.router.events
    .pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let route = this.router.routerState.root;
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route.snapshot.data['layout'] || this.defaultLayout;
      }),
      shareReplay(),
    );


  padding$: Observable<string> = this.layout$
    .pipe(
      map((layout: BkLayout) => this.getPaddingCssValue(layout.paddings)),
    );
  items = [
    {
      'title': 'Dashboard',
      'link': '/overview/real-time-data',
      'icon': 'book-open',
    },
    // {
    //   'title': 'Live Vehicles Overview',
    //   'link': '/overview/vehicles-overview',
    //   'icon': 'pin-outline',
    // },
    {
      'title': 'Analytics',
      'link': '/overview/Analyticsgraph',
      'icon': 'pie-chart',
    },
    // {
    //   'title': 'Recordings',
    //   'link': '/trips',
    //   'icon': 'map',
    //   'children': [
    //     {
    //       'title': 'Record Fleet',
    //       'link': '/trips/trips-overview/default',
    //       'children': null
    //     },
    //     {
    //       'title': 'Upcoming Trip',
    //       'link': '/trips/schedule-trip',
    //       'children': null
    //     },
    //   ]
    // },
    {
      'title': 'Management',
      'link': '/vehicles',
      'icon': 'car',
      'children': [
        {
          'title': 'Vehicle',
          'link': '/vehicles/vehicles-list',
          'children': null
        },
        {
          'title': 'User',
          'link': '/vehicles/vehicles-assignment',
          'children': null
        },
        // {
        //   'title': 'Fuel',
        //   'link': '/vehicles/vehicle-details',
        //   'children': null
        // },
        // {
        //   'title': 'Driver',
        //   'link': '/vehicles/DriverManagement',

        //   'children': null
        // },
        // {
        //   'title': 'Site',
        //   'link': '/vehicles/SiteManagement',
        //   'children': null

        // }

      ]
    },

    {
      'title': 'Logger',
      // 'link': '/trips',
      'icon': 'map',
      'children': [
        {
          'title': 'Fuel',
          'link': '/vehicles/vehicle-details',
          'children': null
        },
        {
          'title': 'Odometer',
          'link': '/trips/schedule-trip',
          'children': null
        },
        {
          'title': 'Load',
          'link': '/vehicles/load-details',
          'children': null
        },
      ]
    },
    // {
    //   'title': 'Configuration',
    //   'link': '/Configuration',
    //   'icon': 'settings',
    //   'children': [
    //     {
    //       'title': 'Manage Permission',
    //       'link': '/Configuration/configurationManagement',
    //       'children': null
    //     },

    //   ]
    // },

    // {
    //   'title': 'Configuration',
    //   'link': '/maintenance',
    //   'icon': 'settings-2',
    //   'children': [
    //     // {
    //     //   'title': 'Maintenance Planner',
    //     //   'link': '/maintenance/maintenance-planner',
    //     //   'children': null
    //     // },
    //     {
    //       'title': 'Manage Permission',
    //       'link': '/maintenance/history',
    //       'children': null
    //     }
    //   ]
    // },
    // {
    //   'title': 'Analytics',
    //   'link': '/analytics',
    //   'icon': 'pie-chart',
    //   'children': null
    // },
    // {
    //   'title': 'Fuel',
    //   'link': '/vehicles/vehicle-details',
    //   'icon': 'pie-chart',
    //   'children': null
    // },

  ];
  constructor(private router: Router, private sidebarService: NbSidebarService, private apiCallService: ApiCallsService) {
    // if (this.isTokenAvailable == true) {
    //   let isRefresh = localStorage.getItem('isRefresh');
    //   if (isRefresh != undefined && isRefresh == "true") {
    //     localStorage.setItem("isRefresh", "false");
    //     // location.reload();
    //   }
    // }
    let screenWidth = window.screen.width;
    if (screenWidth < 900) {
      this.sideBarState = "compacted";
    }
    // console.log(screenWidth, this.sideBarState)
  }
  ngOnInit() {
    
    this.apiCallService.isTokenAvailable.subscribe(res => {
      if (res == true) {
        this.isTokenAvailable = true;
        this.router.navigate(["overview/real-time-data"]);
      }
    })
    // window.onbeforeunload = function() {
    //   localStorage.removeItem(this.isTokenAvailable);
    //   return '';
    // };
  }
  toggleSidebarClick() {
    this.toggleSidebar();
  }
  toggleSidebar() {
    this.sidebarService.toggle(true);
  }
  logout() {
    localStorage.removeItem("userDetails");
    this.isTokenAvailable = false;
    this.router.navigate(["login"]);
    setTimeout(() => {
      location.reload();
    }, 500)
    // window.onbeforeunload = function() {
    //   localStorage.removeItem(this.isTokenAvailable);
    //   return '';
    // };
  }



  private getPaddingCssValue(paddings): string {
    return `${paddings.paddingTop}${paddings.paddingTopUnit} ` +
      `${paddings.paddingRight}${paddings.paddingRightUnit} ` +
      `${paddings.paddingBottom}${paddings.paddingBottomUnit} ` +
      `${paddings.paddingLeft}${paddings.paddingLeftUnit}`;
  }
}
