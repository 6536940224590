import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { ApiCallsService } from "../services/api-calls.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  username:any = '';
  password:any = '';
  
  authFailed;
  eye:boolean = false;
  loginHandler:String = ' ';
  isLoading: boolean= false;
  constructor(
    private apiCallsService: ApiCallsService,
    private router: Router
  ) {
    let isTokenAvailable = localStorage.getItem('userDetails');
    if(isTokenAvailable != null){
      localStorage.setItem("isRefresh", 'true');
      this.router.navigate(["overview/real-time-data"]);
      //window.location.href = "https://sensetracker.livnsense.com/#/overview/real-time-data";
    }
    
  }

  ngOnInit() {
    
  }



  //login
  login() {
    this.isLoading = true;
    const body = {
      username: this.username,
      password: this.password
    };
    if(this.username != '' && this.password != ''){
      this.apiCallsService.login(body).subscribe(
        (data) => {
          // localStorage.removeItem("userDetails");
          const token = data.token.substr(2, data.token.length - 3);
          const stringJSONToken = JSON.stringify({ token: token });
          localStorage.setItem("userDetails", stringJSONToken);
          localStorage.setItem("isRefresh", 'true');
          this.apiCallsService.tokenUpdate();
          this.apiCallsService.setToken();
          // location.reload();
          this.isLoading = false;
          //window.location.replace("https://sensetracker.livnsense.com/#/overview/real-time-data");

          //  let data2=JSON.parse(localStorage.getItem("datas"));
          //  // // (data2);
  
          // if (data["Status"] == "Allow") {
          //   let userDetails = {
          //     name: this.loginForm.value.email,
          //     time: new Date(),
          //     role: data["Role"],
          //   };
          //   // localStorage.setItem("userDetails", JSON.stringify(userDetails));
          //   this.apiCallsService.setUser({
          //     UserName: localStorage.getItem("userDetails"),
          //   });
          // this.loadingImg = false;
          //   if (data["Role"] === 1) this.router.navigate(["fleet/allVehicles"]);
          //   else this.router.navigate(["fleet/overview"]);
          // } else {
          //   this.authFailed = data["Status"];
          //   this.loadingImg = false;
          // }
        },
        (err) => {
           this.loginHandler = err.error.message;
           this.isLoading = false;
           setTimeout(()=>{
              this.loginHandler = " ";
           },5000);
        }
      );
    }
    else if(this.username == "" || this.password == ""){
       this.isLoading = false;
       this.loginHandler = "Please fill all the inputs";
       setTimeout(()=>{
          this.loginHandler = " ";
       },5000);
    }
  }
}
