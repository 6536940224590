// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // baseUrl:'https://api.fleet.livnsense.com/',
  // baseUrl:'http://192.168.1.13:5007/',
  // baseUrl:'https://api.sensetracker.livnsense.com/',
  baseUrl:' https://api.greenmining.livnsense.com/',
  applications: [{
    id: "fleet",
    name: "Fleet",
    navId: "fleet",
    route: "/fleet",
    navbarContains: [{
      name: "Overview",
      route: "/fleet/overview",
      type: 'Fleet Owner'
    }, {
      name: "All Vehicles",
      route: "/fleet/allVehicles",
      type: 'Both'
    }]
  }, {
    id: "details",
    name: "Details",
    navId: "vehicleDetails",
    route: "/vehicleDetails",
    navbarContains: [{
      name: "Live Tracking",
      route: "liveTracking",
      type: 'Fleet Owner'
    }, {
      name: "History Tracking",
      route: "history",
      type: 'Fleet Owner'
    }, {
      name: "Alerts",
      route: "alerts",
      type: 'Fleet Owner'
    }, {
      name: "Statistics",
      route: "statistics",
      type: 'Fleet Owner'
    }, {
      name: "Configuration",
      route: "configuration",
      type: 'Fleet Owner'
    }]
  }, {
    id: "Services",
    name: "Services",
    navId: "services",
    route: "/services",
    navbarContains: [{
      name: "Geofence",
      route: "/services/geofence",
      type: 'Fleet Owner'
    }, {
      name: "Quick View",
      route: "/services/quickview",
      type: 'Admin'
    }, {
      name: "Registration",
      route: "/services/registration",
      type: 'Both'
    }, {
      name: "Report",
      route: "/services/report",
      type: 'Both'
    }]
  }]
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
