import { NgModule } from '@angular/core';

import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
const routes: Routes = [
  { path: 'overview', loadChildren: './dashboard/dashboard.module#DashboardModule' },
  { path: 'vehicles', loadChildren: './vehicles/vehicles.module#VehiclesModule' },
  { path: 'trips', loadChildren: './trips/trips.module#TripsModule' },
  { path: 'maintenance', loadChildren: './maintenance/maintenance.module#MaintenanceModule' },
  // { path: 'analytics', loadChildren: './analytics/analytics.module#AnalyticsModule' },
  // {path:'Configuration', loadChildren:'./'},
  // { path: 'configuration', loadChildren: './configuration/configuration.module#ConfigsModule' },

  

  { path: 'login', component: LoginComponent },
  { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
