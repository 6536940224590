import { Injectable } from "@angular/core";
import * as GlobalVariable from "./constants";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { environment } from "../../environments/environment";
//declare var google: any;
import * as legends from "../services/utils";
const baseUrl = environment["baseUrl"];

@Injectable({
  providedIn: "root",
})
export class ApiCallsService {
  public loggedUser;
  public geofence;
  public reqHeaders: any;
  public tokencheck: any;
  public isTokenAvailable = new Subject();
  public selectedDate:any = "default";

  constructor(private http: HttpClient) {
    let logintoken = JSON.parse(localStorage.getItem("userDetails"))
    let token = 'invalid';
    if (logintoken != undefined) {
      token = logintoken["token"];
    }
    // // console.log( localStorage.getItem("userDetails")) 
    this.reqHeaders = new HttpHeaders({

      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    });

  }

  //update token status
  tokenUpdate() {
    this.isTokenAvailable.next(true);
  }

  //update date status
  set dateUpdate(dateValue:any){
     this.selectedDate = dateValue;
  }

  //get date status
  get dateAdded(){
    return this.selectedDate;
  }

  setToken() {
    let logintoken = JSON.parse(localStorage.getItem("userDetails"));
    let token = 'invalid';
    if (logintoken != undefined) {
      token = logintoken["token"];
    }
    this.reqHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    });
  }

  //login
  login(body): Observable<any> {
    return this.http.post(`${baseUrl}login`, body).pipe(
      map(
        (response: any) => {
          return response;
        },
        (error) => {
          // // (error);
        }
      )
    );
  }

  //overview
  overview(): Observable<any> {
    return this.http.get(`${baseUrl}overview`, { headers: this.reqHeaders }).pipe(
      map(
        (response: any) => {
          return response;
        },
        (error) => {
          // // (error);
        }
      )
    );
  }

  /* Method which returns jwt token */
  public getToken(): string {
    if (localStorage.getItem("userDetails")) {
      return JSON.parse(localStorage.getItem("userDetails"))["token"];
    }
  }

  //getAllDevicesInfo
  getAllDevicesInfo() {
    // // (this.reqHeaders)
    // // console.log(localStorage.getItem("userDetails"))
    return this.http
      .get(GlobalVariable.BASE_API_URL + "allinfo", { headers: this.reqHeaders })
      .pipe(
        map(
          (response) => {
            return response;
          },

          (error) => {
            // // (error);
          }
        )
      );
  }

  //1.getSelectedDeviceAlert
  getSelectedDeviceAlert(startDate, endDate) {
    return this.http
      .get(`${baseUrl}vehicle/alert?start_date=${startDate}&end_date=${endDate}`)
      .pipe(
        map(
          (response) => {
            return response;
          },
          (error) => {
            // // (error);
          }
        )
      );
  }
  // fuel page single 
  getFuelingData() {
    return this.http
      .get(`${baseUrl}mob_app?device_imei=861693038830204&start_date=1665051950&end_date=1665051960`)
      .pipe(
        map(
          (response) => {
            return response;
          },
          (error) => {
            // // (error);
          }

        )
      );
  }

  // fuel page multi select
  // getallFuelingData(){
  //   return this.http
  //   .get(`${baseUrl}get_fueling_data`)
  //   .pipe(
  //     map(
  //       (response)=>{
  //         return response;
  //       },
  //       (error) =>{
  //         // // (error);
  //       }

  //     )
  //   );
  // }
  // startdate=2022-10-01T01:02:00.000Z&enddate=2022-10-07T01:02:00.000Z
  // .get(`${baseUrl}report?startdate=${st_dt}&enddate=${en_dt}&Device_IMEI=${dval}`,{headers:this.reqHeaders})
  
  
  
  
  getReports(dval: any, start: any, end: any) {
    return this.http
      .get(`${baseUrl}report?startdate=${start}&enddate=${end}&Device_IMEI=${dval}`, { headers: this.reqHeaders })
      .pipe(
        map(
          (response) => {
            return response;
          },
          (error) => {
            // // (error);
          }

        )
      );
  }

  getallReports(kval: any, start: any, end: any) {
    return this.http
      .get(`${baseUrl}report_all?Device_IMEI=${kval}&startdate=${start}&enddate=${end}`, { headers: this.reqHeaders })
      .pipe(
        map(
          (response) => {
            return response;
          },
          (error) => {
            // // (error);
          }

        )
      );
  }

  getGraph(aval: any, start: any, end: any){
    return this.http
      .get(`${baseUrl}fuel_odo_graph?start_date=${start}&end_date=${end}&Device_IMEI=${aval}`, { headers: this.reqHeaders })
      .pipe(
        map(
          (response) => {
            return response;
          },
          (error) => {
            // // (error);
          }

        )
      );
  }
  getAnalyticsGraph1(aval: any, start: any, end: any){
    return this.http
      .get(`${baseUrl}fuel_cost_per_day?start_date=${start}&end_date=${end}&Device_IMEI=${aval}`, { headers: this.reqHeaders })
      .pipe(
        map(
          (response) => {
            return response;
          },
          (error) => {
            // // (error);
          }

        )
      );
  }
  getAnalyticsGraph2(aval: any, start: any, end: any){
    return this.http
      .get(`${baseUrl}co2_emission_per_day?start_date=${start}&end_date=${end}&Device_IMEI=${aval}`, { headers: this.reqHeaders })
      .pipe(
        map(
          (response) => {
            return response;
          },
          (error) => {
            // // (error);
          }

        )
      );
  }
  getAnalyticsGraph3(aval: any, start: any, end: any){
    return this.http
      .get(`${baseUrl}harsh_acc_overspeed_count?start_date=${start}&end_date=${end}&Device_IMEI=${aval}`, { headers: this.reqHeaders })
      .pipe(
        map(
          (response) => {
            return response;
          },
          (error) => {
            // // (error);
          }

        )
      );
  }

  getAnalyticsGraph4(aval: any, start: any, end: any){
    return this.http
      .get(`${baseUrl}consumption_idle_vs_total?start_date=${start}&end_date=${end}&device_imei=${aval}`, { headers: this.reqHeaders })
      .pipe(
        map(
          (response) => {
            return response;
          },
          (error) => {
            (error);
          }

        )
      );
  }
  getAnalyticsGraph5(aval: any, start: any, end: any){
    return this.http
      .get(`${baseUrl}trip_load_vs_fuel_consumption?start_date=${start}&end_date=${end}&device_imei=${aval}`, { headers: this.reqHeaders })
      .pipe(
        map(
          (response) => {
            return response;
          },
          (error) => {
            (error);
          }

        )
      );
  }
  getReportsFuel(aval: any, start: any, end: any) {
    return this.http
      .get(`${baseUrl}fuel_consumption_main?device_imei=${aval}&start_date=${start}&end_date=${end}`, { headers: this.reqHeaders })
      .pipe(
        map(
          (response) => {
            return response;
          },
          (error) => {
            // // (error);
          }

        )
      );
  }

  getReportsLoadEfficiency(aval: any, start: any, end: any) {
    return this.http
      .get(`${baseUrl}load_efficiency?device_imei=${aval}&trip_start_time=${start}&trip_end_time=${end}`, { headers: this.reqHeaders })
      .pipe(
        map(
          (response) => {
            return response;
          },
          (error) => {
            // // (error);
          }

        )
      );
  }

  getFuelDetails(aval: any, start: any, end: any) {
    return this.http.get(`${baseUrl}fuel_details?device_imei=${aval}&start_date=${start}&end_date=${end}`, { headers: this.reqHeaders }).pipe(
      map(
        (response) => {
          return response;
        },
        (error) => {
          // // (error);
        }

      )
    );
  }

  getOdometerData(iVal: any) {
    return this.http
      .get(`${baseUrl}odo_data?Device_IMEI=${iVal}`)
      .pipe(
        map(
          (response) => {
            return response;
          },
          (error) => {
            // // (error);
          }

        )
      );
  }

  updateodometer(iVal: any, date: any, data: any) {
    return this.http
      .put(`${baseUrl}update_odo_reading?Device_IMEI=${iVal}&report_date=${date}`, data, { headers: this.reqHeaders })
      .pipe(
        map(
          (response) => {
            return response;
          },
          (err) => {
            // // (err);
          }
        )

      );

  }

  //getSelectedDeviceHistory
  getSelectedDeviceHistory(startDate, endDate) {
    return this.http
      .get(`${baseUrl}vehicle/history?start_date=${startDate}&end_date=${endDate}`)
      .pipe(
        map(
          (response) => {
            return response;
          },
          (error) => {
            // // (error);
          }
        )
      );
  }

  //getSelectedDeviceInfo
  getSelectedDeviceInfo(body) {
    return this.http
      .post(GlobalVariable.BASE_API_URL + "getselecteddevice/info", body, { headers: this.reqHeaders })
      .pipe(
        map(
          (response) => {
            return response;
          },
          (error) => {
            // // (error);
          }
        )
      );
  }

  //1.getVehicleNames
  getVehicleNames() {
    return this.http
      .get(`${baseUrl}vehicle`, { headers: this.reqHeaders })
      .pipe(
        map(
          (response) => {
            return response;
          },
          (error) => {
            // // (error);
          }
        )
      );
  }

  //getAllGeofence
  getAllGeoFence(body) {
    return this.http
      .get(`${baseUrl}geofence`, body)
      .pipe(
        map(
          (response) => {
            return response;
          },
          (err) => {
            // // (err);
          }
        )
      );
  }

  //saveGeofence
  saveGeofence(body) {
    return this.http
      .post(`${baseUrl}geofence`, body)
      .pipe(
        map(
          (response) => {
            return response;
          },
          (err) => {
            // // (err);
          }
        )
      );
  }

  //readgeofence
  readGeofence(body) {
    return this.http
      .get(`${baseUrl}geofence`, body)
      .pipe(
        map(
          (response) => {
            return response;
          },
          (err) => {
            // // (err);
          }
        )
      );
  }

  //mapGeofence
  mapGeofence(body) {
    return this.http
      .post(`${baseUrl}geofencemap`, body)
      .pipe(
        map(
          (response) => {
            return response;
          },
          (err) => {
            // // (err);
          }
        )
      );
  }

  //modifyGeofence
  modifyGeoFence(body) {
    // // ("api", body)
    return this.http
      .put(`${baseUrl}geofence`, body)
      .pipe(
        map(
          (response) => {
            return response;
          },
          (err) => {
            // // (err);
          }
        )
      );
  }

  //deleteGeofence
  deleteGeofence(body) {
    // // ("BODY", )
    return this.http
      .delete(`${baseUrl}geofence?geofence_name=${body.UserName}`)
      .pipe(
        map(
          (response) => {
            return response;
          },
          (err) => {
            // // (err);
          }
        )
      );
  }

  //updateDeviceConfiguration
  updateDeviceConfiguration(body) {
    return this.http
      .post(
        GlobalVariable.BASE_API_URL + "getselecteddevice/updateconfiguration",
        body
      )
      .pipe(
        map(
          (response) => {
            return response;
          },
          (err) => {
            // // (err);
          }
        )
      );
  }


  //return this.http.post(`${baseUrl}login

  //getSettings
  getSettings(body) {
    return this.http.post(`${baseUrl}settings/user-details`, body).pipe(
      map(
        (response) => {
          return response;
        },
        (err) => {
          // // (err);
        }
      )
    );
  }

  //1.update user details
  updateUserInfo(body) {
    return this.http.put(`${baseUrl}user`, body, { headers: this.reqHeaders }).pipe(
      map(
        (response) => {
          return response;
        },
        (err) => {
          // // (err);
        }
      )
    );
  }


  //get kpi history
  getStatistics(body) {
    return this.http
      .get(`${baseUrl}statistics?device_imei=861693038830204&days=100`, body)
      .pipe(
        map(
          (response) => {
            return response;
          },
          (err) => {
            // // (err);
          }
        )
      );
  }

  //check the trip started or not
  checkTripStatus(body) {
    return this.http
      .post(GlobalVariable.BASE_API_URL + "getselecteddevice/tripdetails", body)
      .pipe(
        map(
          (response) => {
            return response;
          },
          (err) => {
            // // (err);
          }
        )
      );
  }





  //2.Register New User
  registerUser(body) {
    return this.http.post(`${baseUrl}user`, body, { headers: this.reqHeaders }).pipe(
      map(
        (response) => {
          return response;
        },
        (err) => {
          // // (err);
        }
      )
    );
  }

  //Register New Device
  registerDevice(body) {
    return this.http
      .post(`${baseUrl}vehicle`, body)
      .pipe(
        map(
          (response) => {
            return response;
          },
          (err) => {
            // // (err);
          }
        )
      );
  }

  //3.Register New Vehicle
  registerVehicle(body) {
    return this.http
      .post(`${baseUrl}vehicle`, body, { headers: this.reqHeaders })
      .pipe(
        map(
          (response) => {
            return response;
          },
          (err) => {
            // // (err);
          }
        )
      );
  }

  //3.get All users
  getAllUsers() {
    return this.http.get(`${baseUrl}users`, { headers: this.reqHeaders }).pipe(
      map(
        (response) => {
          return response;
        },
        (err) => {
          // // (err);
        }
      )
    );
  }

  //4.modify selected user
  modifyUser(body) {
    return this.http.post(`${baseUrl}management/user/modify`, body).pipe(
      map(
        (response) => {
          return response;
        },
        (err) => {
          // // (err);
        }
      )
    );
  }

  //4.modify selected vehicle
  modifyVehicle(body) {
    return this.http
      .put(`${baseUrl}vehicle`, body, { headers: this.reqHeaders })
      .pipe(
        map(
          (response) => {
            return response;
          },
          (err) => {
            // // (err);
          }
        )
      );
  }

  //5.delete selected user
  deleteUser(u_id, uni_id) {
    return this.http.delete(`${baseUrl}user?user_id=${u_id}&uniq_id=${uni_id}`, { headers: this.reqHeaders }).pipe(
      map(
        (response) => {
          return response;
        },
        (err) => {
          // // (err);
        }
      )
    );
  }

  //5.delete selected vehicle
  deleteVehicle(d_imei) {
    return this.http.delete(`${baseUrl}vehicle?device_imei=${d_imei}`, { headers: this.reqHeaders }).pipe(
      map(
        (response) => {
          return response;
        },
        (err) => {
          // // (err);
        }
      )
    );
  }

  // Load data logger APi calls

  getAllLoaddata(aval: any, start: any, end: any) {
    return this.http.get(`${baseUrl}load_data?Device_IMEI=${aval}&start_date=${start}&end_date=${end}`, { headers: this.reqHeaders }).pipe(
      map(
        (response) => {
          return response;
        },
        (error) => {
          // // (error);
        }

      )
    );
  }










  //from here no api calls

  //get all navItems
  // API: GET /application
  public getAll() {
    return environment.applications;
  }

  //set login user
  setUser(user) {
    this.loggedUser = user;
  }
  //get login user
  getUser() {
    return this.loggedUser;
  }

  // //googleMaps Geocoder

  // changeLatLngToAddress(lat, lng) {
  //   // // (lat, lng)
  //   let latlng = new google.maps.LatLng(lat, lng);
  //   let request = {
  //     location: latlng
  //   };
  //   let geocoder = new google.maps.Geocoder();

  //   return geocoder.geocode(request, (results, status) => {
  //     if (status == google.maps.GeocoderStatus.OK) {
  //       // // (results);
  //       return results;

  //     } else {
  //       // // ('Geocoder failed due to: ' + status);
  //     }
  //   });
  // }

  //send legends to map

  getLegendsForMap() {
    return legends.legends;
  }
  getAlertLegendsForMap() {
    return legends.alertLegends;
  }
  //dummy alerts
  getDummyAlerts() {
    return legends.alerts;
  }

  //send kpi to history screen
  getKpiParameters() {
    return legends.kpis;
  }

  //to get history
  getVehiclehistory() {
    return this.http.get(`${baseUrl}vehicle/history?start_date=1662012785&end_date=1662012785&device_imei=861693038830204`).pipe(
      map(
        (res) => {
          // // (res)
          return res;
        },
        (err) => {
          // // (err);
        }
      )
    );

  }
  //1.get all vehicle alerts
  getAllVehicleAlerts(body) {
    return this.http
      .get(`${baseUrl}vehicle/alert?start_date=1662012785&end_date=1662012785&device_imei=861693038830204`, body)
      .pipe(
        map(
          (res) => {
            return res;
          },
          (err) => {
            // // (err);
          }
        )
      );
  }

  //2.get vehicle alerts
  getVehicleAlerts(body) {
    return this.http
      .post(`${baseUrl}vehicle/alert?start_date=1662012785&end_date=1662012785`, body)
      .pipe(
        map(
          (res) => {
            return res;
          },
          (err) => {
            // // (err);
          }
        )
      );
  }

  //get trip details
  getTripDetails(body) {
    return this.http
      .get(`${baseUrl}tripdetails?device_imei=861693038830204`, body)
      .pipe(
        map(
          (response) => {
            return response;
          },
          (err) => {
            // // (err);
          }
        )
      );
  }

  //OnTrip 
  getOnTrip() {
    return this.http
      .get(`${baseUrl}ontrip?device_imei=861693038830204&datetime=1662012785&trip_status=True`)
      .pipe(
        map(
          (response) => {
            return response;
          },
          (err) => {
            // // (err);
          }
        )
      );
  }
  //getPushNotifications
  getPushNotifications(body) {
    return this.http
      .get(`${baseUrl}pushnotification?start_date=1662012785&end_date=1662012785`, body)
      .pipe(
        map(
          (response) => {
            return response;
          },
          (err) => {
            // // (err);
          }
        )
      );
  }


  //getDeviceConfiguration
  getDeviceConfiguration(body) {
    return this.http
      .post(
        `${baseUrl}pushnotification?start_date=1662012785&end_date=1662012785`,
        body
      )
      .pipe(
        map(
          (response) => {
            return response;
          },
          (err) => {
            // // (err);
          }
        )
      );
  }
  //save recorded location. Pass device imei with post body	
  savedRecordedVehicleLocation(imei, body) {
    return this.http
      .post(GlobalVariable.BASE_API_URL + `savetripdetails?device_imei=${imei}`, body, { headers: this.reqHeaders })
      .pipe(
        map(
          (response) => {
            return response;
          },
          (error) => {
            // // (error);	
          }
        )
      );
  }

  // get saved recording details for the passed device imei	
  // Note: this api will not provide us the location lat and lng	
  getVehicleTripDetails(imei) {
    return this.http
      .get(`${GlobalVariable.BASE_API_URL}getsave_id?device_imei=${imei}`, { headers: this.reqHeaders })
      .pipe(
        map(
          (response) => {
            return response;
          },
          (error) => {
            // // (error);	
          }
        )
      );
  }

  //get lat and lng of a saved recording. Pass id	
  getSavedLocation(id) {
    return this.http
      .get(`${GlobalVariable.BASE_API_URL}get_location?id=${id}`, { headers: this.reqHeaders })
      .pipe(
        map(
          (response) => {
            return response;
          },
          (error) => {
            // // (error);	
          }
        )
      );
  }


  // get List of Drivers
  getDriverNames() {
    return this.http
      .get(`${baseUrl}driver`, { headers: this.reqHeaders })
      .pipe(
        map(
          (response) => {
            return response;
          },
          (error) => {
            // // (error);
          }
        )
      );
  }

  // Delete driver information
  deleteDriver(identification) {
    return this.http.delete(`${baseUrl}driver?identification=${identification}`, { headers: this.reqHeaders }).pipe(
      map(
        (response) => {
          return response;
        },
        (err) => {
          // // (err);
        }
      )
    );
  }

  // Add Driver information
  registerDriver(body) {
    return this.http
      .post(`${baseUrl}driver`, body, { headers: this.reqHeaders })
      .pipe(
        map(
          (response) => {
            return response;
          },
          (err) => {
            // // (err);
          }
        )
      );
  }

  // Edit Driver information
  modifyDriver(body) {
    return this.http
      .put(`${baseUrl}driver`, body, { headers: this.reqHeaders })
      .pipe(
        map(
          (response) => {
            return response;
          },
          (err) => {
            // // (err);
          }
        )
      );
  }
} 
